import React, { useState, useCallback, createContext, ReactNode, useRef, useEffect } from 'react';
import Modal from './Modal';
import { useNavigate } from 'react-router-dom';


export const ModalContext = createContext({ createModal: (allowClose: boolean, allowDrawer: boolean, content: ReactNode, kioskRestart?: boolean) => null, closeModal: () => undefined });

export default function ModalContextProvider(props: {children: any}) {
    const navigate = useNavigate();

    const [ modalState, setModalState ] = useState<any>({ showing: false, allowClose: undefined, allowDrawer: undefined, content: undefined, kioskRestart: false });

    const stateRef = useRef<any>(modalState);

    useEffect(() => {
        stateRef.current = modalState;
    }, [modalState]);

    const createModal = useCallback((allowClose: boolean, allowDrawer: boolean, content: ReactNode, kioskRestart?: boolean) => {
        setModalState({showing: true, allowClose: allowClose, allowDrawer: allowDrawer, content: content, kioskRestart: kioskRestart});
    }, [modalState]);

    const closeModal = useCallback(() => {
        if (!stateRef.current.showing) return;

        // Navigate home if kiosk restart
        // NOTE: Loading page has an invisible timeout that also navigates home and closes modal, this is just for when users click the X or background or whatever.
        // TODO why cant this just be done in the button click handler?
        if (stateRef.current.kioskRestart) navigate("/kiosk");

        setModalState((s: any) => ({...s, showing: false}));
        setTimeout(() => {
            setModalState({showing: false, content: undefined, kioskRestart: false});
        }, 200)
    }, [modalState]);

    return (
        <ModalContext.Provider value={{ createModal: createModal, closeModal: closeModal }}>

            <Modal showing={modalState.showing} allowClose={modalState.allowClose} allowDrawer={modalState.allowDrawer}>
                {modalState.content}
            </Modal>

            {props.children}

        </ModalContext.Provider>
    )
}